import { useForm, isInRange } from "@mantine/form";
import { Button, Group, NumberInput, Box, NativeSelect } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { MortgageInput } from "../../../types/MortgageInput";

interface InputFieldsProps {
  mortgageInput: MortgageInput;
  onSubmit: (params: any) => void;
}

export default function InputFields(props: InputFieldsProps) {
  const navigate = useNavigate();
  const form = useForm({
    initialValues: props.mortgageInput,
    validate: {
      closingCostRate: isInRange(
        { min: 0, max: 10 },
        "You must select a value between 0 and 10%."
      ),
      rent: isInRange(
        { min: 0, max: 30000 },
        "You must select a value between 0 and $30,000."
      ),
      downPaymentPercentage: isInRange(
        { min: 0, max: 100 },
        "You must select a value between 0 and 100%."
      ),
      hoaFee: isInRange(
        { min: 0, max: 100000 },
        "You must select a value between 0 and $10,000."
      ),
      homeownerInsuranceRate: isInRange(
        { min: 0, max: 10 },
        "You must select a value between 0 and 10%."
      ),
      houseAnnualAppreciation: isInRange(
        { min: 0, max: 15 },
        "You must select a value between 0 and 15%."
      ),
      inflation: isInRange(
        { min: 0, max: 15 },
        "You must select a value between 0 and 15%."
      ),
      mortgageRate: isInRange(
        { min: 0, max: 100 },
        "You must select a value between 0 and 100%."
      ),
      principal: isInRange(
        { min: 0, max: 1000000000 },
        "You must select a value between 0 and $10,000,000%."
      ),
      propertyTaxRate: isInRange(
        { min: 0, max: 10 },
        "You must select a value between 0 and 10%."
      ),
      rentAnnualGrowthRate: isInRange(
        { min: 0, max: 15 },
        "You must select a value between 0 and 15%."
      ),
      stockAnnualReturn: isInRange(
        { min: 0, max: 15 },
        "You must select a value between 0 and 15%."
      ),
    },
  });

  const onSubmit = (values: any) => {
    props.onSubmit(values);
    navigate("/");
  };

  const parser = (value: string | undefined) => {
    if (value === undefined) {
      return "";
    }

    return value.replace(/\$\s?|(,*)/g, "");
  };

  const formatter = (value: string | undefined) => {
    if (value === undefined) {
      return "";
    }

    return !Number.isNaN(parseFloat(value))
      ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : "$ ";
  };

  return (
    <Box
      component="form"
      style={{ width: "100%" }}
      onSubmit={form.onSubmit((values) => onSubmit(values))}
    >
      <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
        <div className={"Column"}>
          <NumberInput
            label="Principal ($)"
            placeholder="300,000"
            withAsterisk
            step={50000}
            parser={parser}
            formatter={formatter}
            {...form.getInputProps("principal")}
          />
          <NumberInput
            label="Down Payment (%)"
            placeholder="20"
            withAsterisk
            {...form.getInputProps("downPaymentPercentage")}
          />
          <NumberInput
            label="Property tax rate (%)"
            placeholder="1.5%"
            precision={2}
            withAsterisk
            step={0.1}
            {...form.getInputProps("propertyTaxRate")}
          />
          <NumberInput
            label="Homeowner insurance rate (%)"
            placeholder="0.005%"
            precision={2}
            withAsterisk
            step={0.1}
            {...form.getInputProps("homeownerInsuranceRate")}
          />
          <NumberInput
            label="HOA monthly fee ($)"
            placeholder="0"
            withAsterisk
            step={50}
            parser={parser}
            formatter={formatter}
            {...form.getInputProps("hoaFee")}
          />
        </div>
        <div style={{ width: "20px" }} />
        <div className={"Column"}>
          <NumberInput
            label="Mortgage rate (%)"
            placeholder="6%"
            precision={2}
            withAsterisk
            step={0.1}
            {...form.getInputProps("mortgageRate")}
          />
          <NativeSelect
            data={[
              { value: "10", label: "10 Years" },
              { value: "15", label: "15 Years" },
              { value: "20", label: "20 Years" },
              { value: "30", label: "30 Years" },
            ]}
            label="Mortgage duration (Years)"
            withAsterisk
            {...form.getInputProps("mortgageDuration")}
          />
          <NumberInput
            label="Closing costs (%)"
            placeholder="1%"
            precision={2}
            withAsterisk
            step={0.1}
            {...form.getInputProps("closingCostRate")}
          />
          <NumberInput
            label="Inflation (%)"
            placeholder="3%"
            precision={2}
            withAsterisk
            step={0.5}
            {...form.getInputProps("inflation")}
          />
        </div>
        <div style={{ width: "20px" }} />
        <div className={"Column"}>
          <NumberInput
            label="Current Rent ($)"
            placeholder="1500"
            step={100}
            withAsterisk
            parser={parser}
            formatter={formatter}
            {...form.getInputProps("rent")}
          />
          <NumberInput
            label="Rent Annual Growth Rent (%)"
            placeholder="5%"
            precision={2}
            withAsterisk
            step={0.5}
            {...form.getInputProps("rentAnnualGrowthRate")}
          />
          <NumberInput
            label="House Annual Appreciation (%)"
            placeholder="5%"
            precision={2}
            withAsterisk
            step={0.5}
            {...form.getInputProps("houseAnnualAppreciation")}
          />
          <NumberInput
            label="Stock Annual Return (%)"
            placeholder="5%"
            precision={2}
            withAsterisk
            step={0.5}
            {...form.getInputProps("stockAnnualReturn")}
          />
        </div>
      </div>
      <Group position="right" mt="md">
        <Button type="submit" className="Submit-button">
          Submit
        </Button>
      </Group>
    </Box>
  );
}
