
import ImportFormData from "../../data/InputFormData";

import { useState, useEffect } from "react";
import { Table } from 'rsuite';
import { InputNumber } from 'rsuite';
import { InputPicker } from 'rsuite';

const { Cell } = Table;

const EditableCell = ({ rowData, dataKey, onChange, ...props }) => {
  const editing = rowData.status === 'EDIT';
  const paddingTop = editing ? 2 : 12;
  const category = props.category;
  const step = ImportFormData[category][rowData.key]['step'];
  const unit = ImportFormData[category][rowData.key]['unit'] || "$";
  const inputType = ImportFormData[category][rowData.key]['inputType'] || "$";

  const [value, setValue] = useState(rowData[dataKey]);
  useEffect(() => {
    setValue(rowData[dataKey]);
  }, [rowData[dataKey]]);

  let input = null;
  if (inputType === "enum") {
    input = (
      <InputPicker 
        size="sm"
        data={ImportFormData[category][rowData.key]['options']}
        value={value}
        onChange={(value, event) => {
          onChange(rowData.key, value);
        }}
        style={{ margin: 0, padding: 0, marginTop: 2, display: "flex", color: 'black'}}
        block
      />
    );
  } else {
    input = (
      <InputNumber 
        prefix={unit} 
        size="sm"
        className="rs-input"
        value={value}
        onChange={(value, event) => {
          onChange(rowData.key, value);
        }}
        min={ImportFormData[category][rowData.key]['min']}
        max={ImportFormData[category][rowData.key]['max']}
        step={step || 1}
        style={{margin: 0, padding: 0, marginTop: 2}}
      />
    );
  }

  return (
    <Cell {...props} className={editing ? 'table-content-editing' : ''}
      style={{
        fontSize: 12,
        paddingTop: paddingTop,
        color: "black",
      }}
    >
      {editing ? input : (
        <span className="table-content-edit-span" style={{padding: 4,}}>{rowData[dataKey]}</span>
      )}
    </Cell>
  );
};

export default EditableCell;