// @ts-nocheck

import { Table } from 'rsuite';
import { formatCurrency } from "../../../utils/Currency";

import "./table.css";
import "rsuite/dist/rsuite.min.css";

const { Column, HeaderCell, Cell, ColumnGroup } = Table;

const columns = [
  {
    key: 'date',
    label: 'Date',
    columnName: "date",
  },
  {
    key: 'h_payment',
    label: 'Payment',
    columnName: "buy",
  },
  {
    key: 'h_monthly_rent_saved',
    label: 'Rent Savings',
    columnName: "buy",
  },
  {
    key: 'h_tax_savings',
    label: 'Tax Savings',
    columnName: "buy",
  },
  {
    key: 'h_home_equity',
    label: 'House Equity',
    columnName: "buy",
  },
  {
    key: 'h_home_value',
    label: 'Home Value',
    columnName: "buy",
  },
  {
    key: 's_stock_purchase',
    label: 'Stock Purchase',
    columnName: "rent",
  },
  {
    key: 's_stock_equity',
    label: 'Stock Equity',
    columnName: "rent",
  },
];

interface MortgageDetailsTableProps {
  mortgageDetails: any;
}

const currDate = new Date();
const getRows = (mortgageDetails: any) => {
  const res = [];

  if (mortgageDetails?.year?.length) {
    for (let i = 0; i < mortgageDetails.year.length; i++) {
      if (mortgageDetails.h_mortgage_month.length > i) {
        const date = new Date(
          currDate.getFullYear(),
          currDate.getMonth() + mortgageDetails.h_mortgage_month[i],
          1
        );

        const row = {
          date:  date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
        } as any;

        for (const [key, value] of Object.entries(mortgageDetails)) {
          if (value && value?.length > i) {
            row[key] = formatCurrency(value[i]);
          }
        }

        res.push(row);
      }
    }
  }

  return res;
};

export default function MortgageDetailsTable(props: MortgageDetailsTableProps) {
  const data = getRows(props.mortgageDetails);
  return (
    <Table
      loading={!data}
      data={data}
      bordered={true}
      cellBordered={true}
      height={500}
      headerHeight={70}
      rowHeight={30}
      virtualized={true}
    >
      <Column key={"date"} flexGrow={0} align="center">
        <HeaderCell>{"Date"}</HeaderCell>
        <Cell dataKey={"date"} style={{ fontSize: 12, padding: 4, }} />
      </Column>
      <ColumnGroup header="Buy" align="center" flexGrow={1}>
        {columns.map(column => {
          const { key, label, columnName } = column as any;
          if (columnName !== "buy") { return null; }
        
          return (
            <Column key={key} flexGrow={1} align="center">
                <HeaderCell>{label}</HeaderCell>
                <Cell dataKey={key} style={{ fontSize: 12, padding: 4, }} />
            </Column>
          );
        })}
      </ColumnGroup>
      <ColumnGroup header="Rent" align="center" flexGrow={1}>
        {columns.map(column => {
          const { key, label, columnName } = column as any;
          if (columnName !== "rent") { return null; }
          
          return (
            <Column key={key} flexGrow={1} align="center">
                <HeaderCell>{label}</HeaderCell>
                <Cell dataKey={key} style={{ fontSize: 12, padding: 4, }} />
            </Column>
          );
        })}
      </ColumnGroup>
    </Table>
  );
}
