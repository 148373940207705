import OutputTable from "../output/table/OutputTable";
import InputDetails from "../input/InputDetails.js";
import MonthlyCostAnalysisChart from "../output/charts/MonthlyCostAnalysisChart";
import MortgageAmmoritizationChart from "../output/charts/MortgageAmmoritizationChart";
import NetWorthAnalysisChart from "../output/charts/NetWorthAnalysisChart";

import { MortgageInput } from "../../../types/MortgageInput";
import { MortgageDetails } from "../../../types/MortgageDetails";

import "rsuite/dist/rsuite.min.css";

interface MortgageAnalysisProps {
  mortgageInput: MortgageInput;
  mortgageDetails: MortgageDetails | {};
  setFocusedIndex: (input: number) => void;
  updateMortgageInput: (input: MortgageInput) => void;
  setShowLoader: (showLoader: boolean) => void;
}

const PROPERTY_DETAILS_INDEX = 1;

const currencyFormatter = (value: any) => {
  if (value === undefined) {
    return "";
  }

  return !Number.isNaN(parseFloat(value))
    ? `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "$ ";
};

export default function MortgageAnalysis(props: MortgageAnalysisProps) {
  const onNavigateToDetailsPage = () => {
    props.setFocusedIndex(PROPERTY_DETAILS_INDEX);
  };

  return (
    <div className="Mortage-analysis">
      <div className="Input-container">
        <InputDetails
          title={"Property Details"}
          mortgageInput={props.mortgageInput}
          category={"property"}
          updateMortgageInput={props.updateMortgageInput}
          onClick={onNavigateToDetailsPage}
          setShowLoader={props.setShowLoader}
        />
        <InputDetails
          title={"Forecast Details"}
          mortgageInput={props.mortgageInput}
          category={"forecast"}
          updateMortgageInput={props.updateMortgageInput}
          onClick={onNavigateToDetailsPage}
          setShowLoader={props.setShowLoader}
        />
        <InputDetails
          title={"Financing Details"}
          mortgageInput={props.mortgageInput}
          category={"financing"}
          updateMortgageInput={props.updateMortgageInput}
          onClick={onNavigateToDetailsPage}
          setShowLoader={props.setShowLoader}
        />
        <InputDetails
          title={"Current Details"}
          mortgageInput={props.mortgageInput}
          category={"current"}
          updateMortgageInput={props.updateMortgageInput}
          onClick={onNavigateToDetailsPage}
          setShowLoader={props.setShowLoader}
        />
      </div>
      <div
        className="Split-chart-container"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        <div className="Split-chart">
          <MonthlyCostAnalysisChart mortgageDetails={props.mortgageDetails} />
        </div>
        <div className="Split-chart">
          <MortgageAmmoritizationChart
            mortgageDetails={props.mortgageDetails}
          />
        </div>
      </div>
      <div></div>
      <div className="Line-chart-container">
        <NetWorthAnalysisChart mortgageDetails={props.mortgageDetails} />
      </div>
      <OutputTable mortgageDetails={props.mortgageDetails} />
    </div>
  );
}
