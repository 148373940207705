import InputFields from "./InputFields";

import "./input.css";
import { MortgageInput } from "../../../types/MortgageInput";

interface MortgageDetailsProps {
  mortgageInput: MortgageInput;
  setMortgageInput: (input: any) => void;
}

export default function MortgageDetails(props: MortgageDetailsProps) {
  const onSubmit = (params: any) => {
    props.setMortgageInput(params);
  };

  return (
    <div className="Mortgage-details-container">
      <header className="Input-header">
        <h2 className="Header-text">Property Details</h2>
      </header>
      <div className="Input-section">
        <InputFields mortgageInput={props.mortgageInput} onSubmit={onSubmit} />
      </div>
    </div>
  );
}
