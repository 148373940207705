import MortgageDetailsTable from "./MortgageDetailsTable";

interface OutputTableProps {
  mortgageDetails: any;
}

export default function OutputTable(props: OutputTableProps) {
  return (
    <div className="Output-table">
      <header className="Input-header">
        <h2 className="Header-text">Details over Time</h2>
      </header>
      <div className="Output-section" style={{ padding: 0 }}>
        <MortgageDetailsTable mortgageDetails={props.mortgageDetails} />
      </div>
    </div>
  );
}
