import LineComparisonChart from "./templates/LineComparisonChart";

import "./Chart.css";
import { MortgageDetails } from "../../../../types/MortgageDetails";

type MortgateData = {
  xAxis: string;
  yAxis: number;
};

type Series = {
  label: string;
  data: MortgateData[];
};

interface ChartProps {
  mortgageDetails: MortgageDetails | {};
}

const currDate = new Date();

function getDataFromMortgageDetails(mortgageDetails: any) {
  const data: Series[] = [];
  if (mortgageDetails?.h_property_principal_cumulative && mortgageDetails?.h_property_interest_cumulative && mortgageDetails?.h_payment) {
    data.push({
      label: "Principal Cumulative",
      data: mortgageDetails.h_property_principal_cumulative.map((item: number, index: number) => {
        const date = new Date(
          currDate.getFullYear(),
          currDate.getMonth() + index,
          1
        );

        return {
          xAxis: date,
          yAxis: item,
        };
      }),
    });

    data.push({
      label: "Interest Cumulative",
      data: mortgageDetails.h_property_interest_cumulative.map((item: number, index: number) => {
        const date = new Date(
          currDate.getFullYear(),
          currDate.getMonth() + index,
          1
        );

        return {
          xAxis: date,
          yAxis: item,
        };
      }),
    });

    data.push({
      label: "Loan Balance",
      data: mortgageDetails.h_remaining_balance.map((item: number, index: number) => {
        const date = new Date(
          currDate.getFullYear(),
          currDate.getMonth() + index,
          1
        );

        return {
          xAxis: date,
          yAxis: item,
        };
      }),
    });
  }

  return data;
}

function MortgageAmmoritizationChart(props: ChartProps) {
  const data = getDataFromMortgageDetails(props.mortgageDetails);
  if (!data || !data.length) {
    return null;
  }

  const colors = [ "green", "#14709a", "orange"];
  return (
    <LineComparisonChart
      title={"Mortgage Ammoritization"}
      data={data}
      defaultColors={colors}
      legend={[
        { title: "Cumulative Principal", color: colors[0] },
        { title: "Cumulative Interest", color: colors[1] },
        { title: "Loan Balance", color: colors[2] },
      ]}
    />
  );
}

export default MortgageAmmoritizationChart;
