import LineComparisonChart from "./templates/LineComparisonChart";

import "./Chart.css";

type MortgateData = {
  xAxis: string;
  yAxis: number;
};

type Series = {
  label: string;
  data: MortgateData[];
};

const currDate = new Date();

function getDataFromMortgageDetails(mortgageDetails: any) {
  const data: Series[] = [];

  if (mortgageDetails?.h_payment && mortgageDetails?.h_monthly_rent_saved) {
    const homePayment = mortgageDetails.h_payment.filter(
      (item: any, index: number) => {
        if (index % 12 !== 1) {
          return false;
        }
        return true;
      }
    );

    data.push({
      label: "Monthly Buy",
      data: homePayment.map((item: number, index: number) => {
        const date = new Date(
          currDate.getFullYear(),
          currDate.getMonth() + index * 12,
          1
        );

        return {
          xAxis: date,
          yAxis: item,
        };
      }),
    });

    const hMonthlyRent = mortgageDetails.h_monthly_rent_saved.filter(
      (item: any, index: number) => {
        if (index % 12 !== 1) {
          return false;
        }
        return true;
      }
    );

    data.push({
      label: "Monthly Rent",
      data: hMonthlyRent.map((item: number, index: number) => {
        const date = new Date(
          currDate.getFullYear(),
          currDate.getMonth() + index * 12,
          1
        );

        return {
          xAxis: date,
          yAxis: item,
        };
      }),
    });
  }

  return data;
}

function MonthlyCostAnalysisChart(outputData: any) {
  const data = getDataFromMortgageDetails(outputData.mortgageDetails);
  if (!data || !data.length) {
    return null;
  }

  const colors = ["#14709a", "orange"];
  return (
    <LineComparisonChart
      title={"Monthly Costs over Time"}
      defaultColors={colors}
      data={data}
      legend={[
        { title: "Buy", color: colors[0] },
        { title: "Rent", color: colors[1] },
      ]}
    />
  );
}

export default MonthlyCostAnalysisChart;
