import LineComparisonChart from "./templates/LineComparisonChart";

import "./Chart.css";

type MortgateData = {
  xAxis: string;
  yAxis: number;
};

type Series = {
  label: string;
  data: MortgateData[];
};

const currDate = new Date();

function getDataFromMortgageDetails(mortgageDetails: any) {
  const data: Series[] = [];

  if (mortgageDetails?.h_home_equity && mortgageDetails?.s_stock_equity) {
    data.push({
      label: "Property",
      data: mortgageDetails.h_home_equity.map((item: number, index: number) => {
        const date = new Date(
          currDate.getFullYear(),
          currDate.getMonth() + index,
          1
        );

        return {
          xAxis: date,
          yAxis: item,
        };
      }),
    });
    data.push({
      label: "Stock",
      data: mortgageDetails.s_stock_equity.map(
        (item: number, index: number) => {
          const date = new Date(
            currDate.getFullYear(),
            currDate.getMonth() + index,
            1
          );

          return {
            xAxis: date,
            yAxis: item,
          };
        }
      ),
    });
  }

  return data;
}

function NetWorthAnalysisChart(outputData: any) {
  const data = getDataFromMortgageDetails(outputData.mortgageDetails);
  if (!data || !data.length) {
    return null;
  }

  const colors = ["#14709a", "orange"];
  return (
    <LineComparisonChart
      data={data}
      title={"Total Net Worth over Time"}
      defaultColors={colors}
      legend={[
        { title: "Buy", color: colors[0] },
        { title: "Rent", color: colors[1] },
      ]}
    />
  );
}

export default NetWorthAnalysisChart;
