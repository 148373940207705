import { useEffect, useState } from "react";

import MortgageDetails from "./js/components/input/MortgageDetails";
import Logo from "./js/components/sidebar/Logo";
import FinancialApiService from "./js/services/FinancialApiService";
import SideBarSection from "./js/components/sidebar/SideBarSection";
import Header from "./js/components/container/Header";
import Footer from "./js/components/container/Footer";
import MortgageAnalysis from "./js/components/container/MortgageAnalysis";
import SampleMortgageInput from "./js/data/SampleMortgageInput";
import EmptyMortgageInput from "./js/data/EmptyMortgageInput";
import ShareModal from './js/components/share/ShareModal';

import { useParams } from "react-router-dom";
import { Message } from "rsuite";
import { MortgageInput } from "./types/MortgageInput";

import "./App.css";

interface ContainerProps {
  index: number;
}

function Container(props: ContainerProps) {
  const { id } = useParams()

  const [mortgageInput, setMortgageInput] = useState(id ? EmptyMortgageInput : SampleMortgageInput);
  const [mortgageDetails, setMortgageDetails] = useState({});
  const [focusedIndex, setFocusedIndex] = useState(props.index);
  const [showLoader, setShowLoader] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [shareUrl, setShareUrl] = useState('https://app.calco.ai');
  const [urlLoaded, setUrlLoaded] = useState(false);

  const onClickShare = async () => {
    setShowShareModal(true);
    const url = await FinancialApiService.GetShareableLink(mortgageInput);
    if (url) {
      setShareUrl(url);
      setUrlLoaded(true);
    }
  }

  // Fetch saved mortgage input from server.
  useEffect(() => {
    const getSavedMortgageInput = async () => {
      if (!id) { return; }

      setShowLoader(true);
      const savedMortgageInput = await FinancialApiService.GetSavedMortageInput(id);
      setMortgageInput(savedMortgageInput?.input as MortgageInput);
      setMortgageDetails(savedMortgageInput?.mortgage_details);
      setShowLoader(false);
    }

    getSavedMortgageInput();
  }, [id]);

  // Fetch mortgage calculations from server.
  useEffect(() => {
    const fetchMortgageData = async () => {
      const data = await FinancialApiService.GetMortgageDetails(mortgageInput);
      setMortgageDetails(data);
      setShowLoader(false);
    };
    fetchMortgageData();
  }, [mortgageInput]);

  useEffect(() => {
    setFocusedIndex(props.index);
  }, [props.index]);

  return (
    <div className="App">
      <div className="Sidebar" style={{ position: "fixed" }}>
        <Logo className={"Logo"} classNameText={"Logo-text"} imgSize={44} />
        <SideBarSection
          index={0}
          focusedIndex={focusedIndex}
          onSelect={(index: number) => setFocusedIndex(index)}
        />
        <SideBarSection
          index={1}
          focusedIndex={focusedIndex}
          onSelect={(index: number) => setFocusedIndex(index)}
        />
        <SideBarSection
          index={2}
          focusedIndex={focusedIndex}
          onSelect={(index: number) => setFocusedIndex(index)}
        />
        <SideBarSection
          index={3}
          focusedIndex={focusedIndex}
          onSelect={(index: number) => setFocusedIndex(index)}
        />
        <SideBarSection
          index={4}
          focusedIndex={focusedIndex}
          onSelect={(index: number) => setFocusedIndex(index)}
        />
      </div>
      <div className="Container">
        <div className="Header-container">
          <Header
            focusedIndex={focusedIndex}
            showLoader={showLoader}
            onClickShare={onClickShare}
          />
        </div>

      <Message className="Mobile-banner" showIcon type="info" closable style={{borderRadius: 0}}>
        For an optimal experience, please use a device with a larger screen.
      </Message>

        <div className="Content">
          {focusedIndex === 0 && (
            <MortgageAnalysis
              mortgageInput={mortgageInput}
              mortgageDetails={mortgageDetails}
              updateMortgageInput={setMortgageInput}
              setFocusedIndex={setFocusedIndex}
              setShowLoader={setShowLoader}
            />
          )}
          {focusedIndex === 1 && (
            <MortgageDetails mortgageInput={mortgageInput} setMortgageInput={setMortgageInput} />
          )}
        </div>
        <Footer />
      </div>
      <ShareModal open={showShareModal} onClose={() => setShowShareModal(false)} url={shareUrl} urlLoaded={urlLoaded} />
    </div>
  );
}

export default Container;
