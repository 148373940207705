import XRestClient from "./XRestClient";

import { MortgageInput } from "../../types/MortgageInput";
import sampleMortgageInput from "../data/SampleMortgageInput";

class FinancialApiService {
  static ConvertFromMortgageInputAPI = (res: any) => {
    return {
      ...sampleMortgageInput,
      closingCostRate: res.policy_assumptions.closing_cost_rate * 100,
      rent: res.economic_assumptions.initial_rent,
      downPaymentPercentage: res.year_to_mortgage[0].down_payment_pct * 100,
      hoaFee: res.additional_monthly_cost || 0,
      homeownerInsuranceRate: res.policy_assumptions.homeowner_insurance_rate * 100,
      houseAnnualAppreciation: res.economic_assumptions.house_annual_appreciation * 100,
      inflation: res.economic_assumptions.inflation * 100,
      mortgageDuration: res.year_to_mortgage[0].years.toString(),
      mortgageRate: res.year_to_mortgage[0].interest_rate * 100,
      principal: res.purchase_price,
      propertyTaxRate: res.policy_assumptions.property_tax_rate * 100,
      rentAnnualGrowthRate: res.economic_assumptions.rent_annual_growth_rate * 100,
      stockAnnualReturn: res.economic_assumptions.stock_annual_return * 100,
      marginalTaxRate: res.policy_assumptions.marginal_tax_rate * 100,
    };
  }

  static ConvertToMortgageInputAPI = (mortgageInput: MortgageInput) => {
    return {
      purchase_price: mortgageInput.principal,
      additional_monthly_cost: mortgageInput.hoaFee,
      year_to_mortgage: {
        0: {
          mortgage_type: 0,
          down_payment_pct: mortgageInput.downPaymentPercentage / 100,
          years: mortgageInput.mortgageDuration?.toString() || "30",
          interest_rate: mortgageInput.mortgageRate / 100,
        },
      },
      policy_assumptions: {
        property_tax_rate: mortgageInput.propertyTaxRate / 100,
        closing_cost_rate: mortgageInput.closingCostRate / 100,
        homeowner_insurance_rate: mortgageInput.homeownerInsuranceRate / 100,
        selling_cost: 0.08,
        marginal_tax_rate: mortgageInput.marginalTaxRate / 100,
      },
      economic_assumptions: {
        initial_rent: mortgageInput.rent,
        rent_annual_growth_rate: mortgageInput.rentAnnualGrowthRate / 100,
        house_annual_appreciation:
          mortgageInput.houseAnnualAppreciation / 100,
        stock_annual_return: mortgageInput.stockAnnualReturn / 100,
        inflation: mortgageInput.inflation / 100,
      },
    };
  }

  static GetMortgageDetails = async (mortgageInput: MortgageInput) => {
    const request = {
      input: FinancialApiService.ConvertToMortgageInputAPI(mortgageInput),
    };

    try {
      const res = await XRestClient.RequestMortgageDetails(request);
      return res?.mortgage_details || {};
    } catch (err: unknown) {
      console.log(err?.toString());
    }
  };

  static GetSavedMortageInput = async (id: string) => {
    try {
      const data = {
        record_key: id,
        include_response: true,
      }
      const res = await XRestClient.GetSavedMortgageInput(data);
      return {
        input: FinancialApiService.ConvertFromMortgageInputAPI(res.input),
        mortgage_details: res.mortgage_details,
      }
    } catch (err: unknown) {
      console.log(err?.toString());
    }
  };

  static GetShareableLink = async (mortgageInput: MortgageInput) => {
    const request = {
      input: FinancialApiService.ConvertToMortgageInputAPI(mortgageInput),
    };

    try {
      const res = await XRestClient.GetShareableLink(request);
      return res.url;
    } catch (err: unknown) {
      console.log(err?.toString());
    }
    return "";
  };

}

export default FinancialApiService;
