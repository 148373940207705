import { MortgageInput } from "./../../types/MortgageInput";

const sampleMortgageInput: MortgageInput = {
  principal: 650000,
  propertyTaxRate: 1.5,
  homeownerInsuranceRate: 0.5,
  hoaFee: 0,
  houseAnnualAppreciation: 5,
  rentAnnualGrowthRate: 5,
  stockAnnualReturn: 8,
  inflation: 3,
  mortgageRate: 6.2,
  downPaymentPercentage: 20,
  mortgageDuration: "30",
  closingCostRate: 1,
  rent: 2500,
  marginalTaxRate: 20,
};

export default sampleMortgageInput;
