interface LegendProps {
  legend: any[];
}

// <div className="Legend-container-row">
//   <div
//     className="Legend-container-color"
//     style={{ backgroundColor: "orange" }}
//   ></div>
//   <p className="Legend-container-text">Rent</p>
// </div>;

function Legend(props: LegendProps) {
  return (
    <div className="Legend-container">
      {props.legend.map((item, index) => {
        return (
          <div key={index.toString()} className="Legend-container-row">
            <div
              className="Legend-container-color"
              style={{ backgroundColor: item.color }}
            ></div>
            <p className="Legend-container-text">{item.title}</p>
          </div>
        );
      })}
    </div>
  );
}

export default Legend;
