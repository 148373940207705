import { Link } from "react-router-dom";

import options from "../../data/SideBarOptions";

import "./Sidebar.css";

interface SideBarSectionProps {
  index: number;
  focusedIndex: number;
  onSelect: (index: number) => void;
}

export default function SideBarSection(props: SideBarSectionProps) {
  const isFocused = props.index === props.focusedIndex;
  const title = options[props.index].title;
  const imgSrc = options[props.index].imgSrc;
  const routes = options[props.index].routes;
  const hasRoute = routes && routes.length;

  return (
    <Link
      to={hasRoute ? routes[0] : "mailto:support@calco.app"}
      style={{ textDecoration: "none" }}
      onClick={() =>
        props.onSelect(hasRoute ? props.index : props.focusedIndex)
      }
    >
      <div
        className="Sidebar-container"
        style={{
          backgroundColor: isFocused ? "#E1F5FF" : "transparent",
        }}
      >
        <img alt={title} src={imgSrc} className="Sidebar-icon"></img>
        <p className="Sidebar-text">{title}</p>
      </div>
    </Link>
  );
}
