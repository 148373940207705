import axios from "axios";

var _local = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
var BASE_URL = _local ? "http://localhost:8080" : "https://calcoapi.pearapi.com";

const timeout = 10000;

class XRestClient {
  static async GetShareableLink(payload: any) {
    const res = await XRestClient.SendCustomRequest(
      JSON.stringify(payload),
      "/v1/genlink",
      "POST",
    );
    return (res && res.data) || {};
  }

  static async GetSavedMortgageInput(payload: any) {
    const res = await XRestClient.SendCustomRequest(
      JSON.stringify(payload),
      "/v1/getsavedquery",
      "POST",
    );
    return (res && res.data) || {};
  }

  static async RequestMortgageDetails(payload: any) {
    const res = await XRestClient.SendCustomRequest(
      JSON.stringify(payload),
      "/v1/calculate",
      "POST",
    );
    return (res && res.data) || {};
  }

  static async SendCustomRequest(data: string, endpoint: string, method: string) {
    let url = BASE_URL + endpoint;
    var request = {
      method,
      url,
      data,
      timeout,
    };

    const res = await axios.request(request);
    return res;
  }
}

export default XRestClient;
