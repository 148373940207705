import "./Container.css";

import { Button, Loader } from 'rsuite';

import ghost from "./../../images/ghost.png";
import options from "../../data/SideBarOptions";
import Logo from "../sidebar/Logo";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const nth = function (d: number) {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

interface HeaderProps {
  focusedIndex: number;
  showLoader: boolean;
  onClickShare: () => void;
}

export default function Header(props: HeaderProps) {
  const time = new Date();
  const focusedIndex = props.focusedIndex;
  const headerTitle = options[focusedIndex].headerTitle;
  
  return (
    <header className="Header">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div>
          <Logo className={"Logo-mobile"} classNameText={"Logo-mobile-text"} imgSize={30}/>
          <h2 className="Header-title-text">{headerTitle}</h2>
          <h4 className="Header-description-text">{`${weekday[time.getDay()]}, ${
            month[time.getMonth()]
            } ${time.getDate() + nth(time.getDate())} ${time.getFullYear()}`}</h4>
        </div>
        {props.showLoader && <Loader color="black" size="sm" style={{ margin: 12, marginBottom: 2, marginTop: 8, marginLeft: 15 }} />}
      </div>
      <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
        <Button
          appearance="primary"
          active
          className="Header-share-button"
          size="sm"
          onClick={() => props.onClickShare()}
        >Share</Button>
        <button className="Header-avatar-button">
          <img className="Header-image" src={ghost} alt={"User profile"}></img>
          <div className="Header-green-dot"></div>
        </button>
      </div>
    </header>
  );
}
