const InputFormData = {
  property: {
    principal: {
        title: "Principal",
        inputType: "currency",
        unit: "$",
        step: 10000,
        min: 0,
        max: 10000000,
    },
    propertyTaxRate: {
        title: "Property Tax Rate",
        inputType: "percentage",
        unit: "%",
        step: 0.1,
        min: 0,
        max: 100,
    },
    hoaFee: {
        title: "Monthly HOA",
        inputType: "currency",
        unit: "$",
        step: 50,
        min: 0,
        max: 5000,
    },
    homeownerInsuranceRate: {
        title: "Property Insurance",
        inputType: "percentage",
        unit: "%",
        step: 0.1,
        min: 0,
        max: 100,
    },
  },
  financing: {
    mortgageRate: {
        title: "Mortgage Rate",
        inputType: "percentage",
        unit: "%",
        step: 0.1,
        min: 0,
        max: 100,
    },
    downPaymentPercentage: {
        title: "Down Payment",
        inputType: "percentage",
        unit: "%",
        step: 1,
        min: 0,
        max: 100,
    },
    mortgageDuration: {
      title: "Mortgage Duration",
      inputType: "enum",
      options: [
        { value: "10", label: "10 Years" },
        { value: "15", label: "15 Years" },
        { value: "20", label: "20 Years" },
        { value: "30", label: "30 Years" },
      ],
    },
    closingCostRate: {
        title: "Closing Costs",
        inputType: "percentage",
        unit: "%",
        step: 0.1,
        min: 0,
        max: 100,
    },
  },
  current: {
    rent: {
        title: "Monthly Rent",
        inputType: "currency",
        unit: "$",
        step: 100,
        min: 0,
        max: 20000,
    },
    marginalTaxRate: {
        title: "Marginal Tax Rate",
        inputType: "percentage",
        unit: "%",
        step: 1,
        min: 0,
        max: 100,
    },
  },
  forecast: {
    houseAnnualAppreciation: {
        title: "House Annual Appreciation",
        inputType: "percentage",
        unit: "%",
        step: 1,
        min: 0,
        max: 100,
    },
    rentAnnualGrowthRate: {
        title: "Rent Annual Growth",
        inputType: "percentage",
        unit: "%",
        step: 1,
        min: 0,
        max: 100,
    },
    stockAnnualReturn: {
        title: "Stock Annual Return",
        inputType: "percentage",
        unit: "%",
        step: 1,
        min: 0,
        max: 100,
    },
    inflation: {
        title: "Inflation",
        inputType: "percentage",
        unit: "%",
        step: 1,
        min: 0,
        max: 100,
    },
  },
}

export default InputFormData;