import { MortgageInput } from "./../../types/MortgageInput";

const EmptyMortgageInput: MortgageInput = {
  principal: 0,
  propertyTaxRate: 0,
  homeownerInsuranceRate: 0,
  hoaFee: 0,
  houseAnnualAppreciation: 0,
  rentAnnualGrowthRate: 0,
  stockAnnualReturn: 0,
  inflation: 0,
  mortgageRate: 0,
  downPaymentPercentage: 0,
  mortgageDuration: "30",
  closingCostRate: 0,
  rent: 0,
  marginalTaxRate: 0,
};

export default EmptyMortgageInput;
