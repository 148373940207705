import { Link } from "react-router-dom";

import logo from "../../images/calco.png";

interface LogoProps {
  className: string;
  classNameText: string;
  imgSize: number;
}

export default function Logo(props: LogoProps) {
  return (
    <header className={props.className}>
      <img src={logo} style={{height: props.imgSize, width: props.imgSize}} alt="logo" />
      <Link to={"/"} style={{ textDecoration: "none" }}>
        <h4 className={props.classNameText}>Calco</h4>
      </Link>
    </header>
  );
}
