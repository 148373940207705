import "./share.css";

import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    RedditShareButton,
    RedditIcon,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon,
    EmailShareButton,
    EmailIcon
} from "react-share";

import { Button, Modal } from "rsuite";
import Loader from 'rsuite/Loader';

interface ShareModalProps {
  url: string;
  urlLoaded: boolean;
  open: boolean;
  onClose: () => void;
}

export default function ShareModal(props: ShareModalProps) {
    const url = props.url;
    const urlLoaded = props.urlLoaded;
    
    const onCopyUrl = async () => {
        await navigator.clipboard.writeText(url);
    }

    return (
        <Modal backdrop={'static'} open={props.open}>
            <div className="popup">
                <header>
                    <span>Share Calco</span>
                    <button className="close" onClick={() => props.onClose()}><i className="uil uil-times"></i></button>
                </header>
                <div className="content">
                    <p>Share this link via</p>
                    <ul className="icons">
                        <FacebookShareButton url={url}>
                            <FacebookIcon size={48} round={true} />
                        </FacebookShareButton>
                        <TwitterShareButton url={url}>
                            <TwitterIcon size={48} round={true} />
                        </TwitterShareButton>
                        <RedditShareButton url={url}>
                            <RedditIcon size={48} round={true} />
                        </RedditShareButton>
                        <TelegramShareButton url={url}>
                            <TelegramIcon size={48} round={true} />
                        </TelegramShareButton>
                        <WhatsappShareButton url={url}>
                            <WhatsappIcon size={48} round={true} />
                        </WhatsappShareButton>
                        <EmailShareButton url={url}>
                            <EmailIcon size={48} round={true} />
                        </EmailShareButton>
                    </ul>
                    <p>Or copy link</p>
                    <div className="field">
                        <i className="url-icon uil uil-link"></i>
                        { !urlLoaded ? <Loader /> : null}
                        <input type="text" value={url}></input>
                        <Button
                            active
                            appearance="primary"
                            style={{ textAlign: "center", paddingRight: 25 }}
                            onClick={onCopyUrl}
                        >
                            Copy
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
