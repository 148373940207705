import { Route, Routes } from "react-router-dom";

import Container from "./Container";
import options from "./js/data/SideBarOptions";

export function App() {
  return (
    <Routes>
      {options.map((item) => {
        const routes = item?.routes || [];
        return routes.map((route) => {
          return (
            <Route path={route} element={<Container index={item.index} />} />
          );
        });
      })}
    </Routes>
  );
}
