import home from "../images/home.png";
import magnifier from "../images/magnifier.png";
import pieChart from "../images/pie-chart.png";
import money from "../images/money.png";
import support from "../images/support.png";

const options = [
  {
    title: "Analysis Tool",
    headerTitle: "Buy vs Rent Analysis Tool",
    index: 0,
    imgSrc: magnifier,
    routes: ["/", "/home", "/report", "/report/:id"],
  },
  {
    title: "Property Details",
    headerTitle: "Property Details (WIP)",
    index: 1,
    imgSrc: home,
    routes: ["/property", "/details"],
  },
  {
    title: "Refinance",
    headerTitle: "Refinance Tool (WIP)",
    index: 2,
    imgSrc: money,
    routes: ["/refinance"],
  },
  {
    title: "Monthly Costs",
    headerTitle: "Mortgage Ammoritization Tool (WIP)",
    index: 3,
    imgSrc: pieChart,
    routes: ["/monthly"],
  },
  {
    title: "Support",
    headerTitle: "Support",
    index: 4,
    imgSrc: support,
  },
];

export default options;
