import React from "react";

import { Chart, AxisOptions, UserSerie } from "react-charts";

import Legend from "./Legend";

type MortgateData = {
  xAxis: string;
  yAxis: number;
};

type ChartProps = {
  title: string;
  data: UserSerie<MortgateData>[];
  defaultColors: any[];
  legend: any[];
};

const currDate = new Date();

function LineComparisonChart(props: ChartProps) {
  const primaryAxis = React.useMemo(
    (): AxisOptions<MortgateData> => ({
      getValue: (val) => val.xAxis,
      hardMin: currDate,
      formatters: {
        scale: (value: any) => {
          return `${value?.getFullYear()}`;
        },
        tooltip: (value: any) => {
          return `Year: ${value?.getFullYear()}`;
        },
        cursor: (value: any) => {
          return `Year: ${value?.getFullYear()}`;
        },
      },
    }),
    []
  );

  const secondaryAxes = React.useMemo(
    (): AxisOptions<MortgateData>[] => [
      {
        getValue: (val) => val.yAxis,
        elementType: "line",
        formatters: {
          scale: (value: number) => {
            return !Number.isNaN(value)
              ? `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "$ ";
          },
        },
      },
    ],
    []
  );

  const data = props.data;
  if (!data || !data.length) {
    return null;
  }

  return (
    <div className="Chart-table">
      <header className="Chart-header">
        <h2 className="Header-text">{props.title}</h2>
        <Legend legend={props.legend} />
      </header>
      <div className="Chart-section">
        <div
          style={{
            height: 350,
            flex: 1,
            width: "100%",
            marginTop: 5,
            marginRight: 20,
          }}
        >
          <Chart
            options={{
              data,
              primaryAxis,
              secondaryAxes,
              tooltip: true,
              defaultColors: props.defaultColors,
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LineComparisonChart;
