import ImportFormData from "../../data/InputFormData";
import EditableCell from "./EditableCell";

import { useState, useCallback, useEffect } from 'react';
import { Table } from 'rsuite';

const { Column, HeaderCell, Cell } = Table;

const _ = require('lodash');

const getData = (mortgageInput, category) => {
  const res = [];
  for (const [key, value] of Object.entries(mortgageInput)) {
    if (ImportFormData[category] !== undefined && ImportFormData[category][key] !== undefined) {
      res.push({
        key,
        value,
        title: ImportFormData[category][key]['title'],
        status: 'EDIT',
      });
    } 
  }
  return res;
}

export default function InputDetails(props) {
  const [mortgageInput, setMortgageInput] = useState(props.mortgageInput);
  const data = getData(mortgageInput, props.category);
  const numItems = Object.keys(ImportFormData[props.category]).length;
  const rowHeight = 40;

  function handleDebounceFn(inputValue) {
    props.updateMortgageInput(inputValue);
  }

  useEffect(() => {
    setMortgageInput(props.mortgageInput);
  }, [props.mortgageInput])

  const debounceFn = useCallback(_.debounce(handleDebounceFn, 2000), []);

  const onChange = (key, value) => {
    const updatedMortgageInput = JSON.parse(JSON.stringify(mortgageInput));
    updatedMortgageInput[key] = value;
    setMortgageInput(updatedMortgageInput);
    props.setShowLoader(true);
    debounceFn(updatedMortgageInput);
  }

  return (
    <div className="Input-details">
      <div className="Input-text-container">
        <h2 className="Header-text" style={{marginLeft: 20}}>{props.title}</h2>
        <Table
          height={numItems * rowHeight}
          data={data}
          style={{ width: '100%', height: "100%", marginTop: 0 }}
          headerHeight={0}
          rowHeight={rowHeight}
          disabledScroll={true}
          bordered={true}
          cellBordered={false}
        >
          <Column width={100} align="left" flexGrow={1} fixed>
            <HeaderCell></HeaderCell>
            <Cell dataKey="title" style={{fontSize: 12, padding: 6, paddingTop: 11, paddingLeft: 20, color: "black"}} />
          </Column>

          <Column width={150} align="center" flexGrow={1} fixed>
            <HeaderCell></HeaderCell>
            <EditableCell dataKey="value" onChange={onChange} category={props.category} />
          </Column>
        </Table>
      </div>
    </div>
  );
}
