// File for all helper functions and utils.

const formatCurrency = (units: string) => {
  if (units && parseInt(units)) {
    return parseInt(units).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  }

  return units;
};

export { formatCurrency };
